import PropTypes from 'prop-types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Collapse } from '@mui/material';
// hooks
import useActiveLink from '../../../../hooks/useActiveLink';
// components
import { NavSectionVertical } from '../../../../components/nav-section';
//
import NavItem from './NavItem';

// ----------------------------------------------------------------------

NavList.propTypes = {
  item: PropTypes.object,
  onHandleClose: PropTypes.func
};

export default function NavList({ item, onHandleClose }) {
  const { pathname } = useLocation();

  const { path, children } = item;

  const { isExternalLink } = useActiveLink(path);

  const [open, setOpen] = useState(false);
  return (
    <>
      <NavItem
        item={item}
        open={open}
        onClick={() => {
          setOpen(!open)
          if (!item.children) onHandleClose();
        }}
        active={pathname === path}
        isExternalLink={isExternalLink}
      />

      {!!children && (
        <Collapse in={open} unmountOnExit>
          <NavSectionVertical
            data={children}
          />
        </Collapse>
      )}
    </>
  );
}
