import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Box, Grid, Link, Stack, Divider, Container, Typography, IconButton } from '@mui/material';
// images
import PaltaQR from '../../assets/images/palta-qr.png';
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';
import Image from '../../components/image';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Palta',
    externalLinks: true,
    children: [
      { name: 'Inicio', href: '/' },
      { name: 'Mendoza Activa', href: 'https://www.mendoza.gov.ar/mendozaactiva/' },
      {
        name: 'Preguntas frecuentes',
        href: 'https://www.mendoza.gov.ar/economia/preguntas-frecuentes/',
      },
    ],
  },
  {
    headline: 'Legales',
    externalLinks: false,
    children: [
      { name: 'Términos y condiciones de uso de Palta', href: '/notes/términos-y-condiciones' },
      {
        name: 'Términos y condiciones de Mendoza Activa',
        href: '/notes/términos-y-condiciones-mendoza-activa',
      },
      { name: 'Defensa del Consumidor Online', href: '/notes/defensa-consumidor' },
      { name: 'Información al Usuario Financiero', href: '/notes/información-usuario-financiero' },
      { name: 'Políticas de privacidad', href: '/notes/políticas-privacidad' },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const location = useLocation();

  const socials = [
    {
      value: 'whatsapp',
      name: 'Whatsapp',
      icon: 'ic:round-whatsapp',
      color: '#1877F2',
      path: 'https://chat.whatsapp.com/KWfDXioytPxGd3gEtpRZ7A',
    },
    {
      value: 'discord',
      name: 'Discord',
      icon: 'ic:baseline-discord',
      color: '#1877F2',
      path: 'https://discord.gg/4y4JmaVrSR',
    },
    {
      value: 'facebook',
      name: 'FaceBook',
      icon: 'eva:facebook-fill',
      color: '#1877F2',
      path: 'https://www.facebook.com/102288531678609/',
    },
    {
      value: 'instagram',
      name: 'Instagram',
      icon: 'ant-design:instagram-filled',
      color: '#E02D69',
      path: 'https://instagram.com/palta_app',
    },
    {
      value: 'linkedin',
      name: 'Linkedin',
      icon: 'eva:linkedin-fill',
      color: '#007EBB',
      path: 'https://www.linkedin.com/company/paltaapp/',
    },
    {
      value: 'playStore',
      name: 'Play Store',
      icon: 'bxl:play-store',
      color: '#007EBB',
      path: 'https://play.google.com/store/apps/details?id=xyz.appmaker.lwbxjf',
    },
    {
      value: 'appStore',
      name: 'App Store',
      icon: 'simple-icons:appstore',
      color: '#007EBB',
      path: 'https://apps.apple.com/ar/app/palta-app/id1596161265',
    },
  ];

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>

          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              Tu nueva billetera. <br />
              Dirección - Olegario V. Andrade 315, Ciudad, Mendoza
            </Typography>

            <Stack
              spacing={1}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 5,
                mb: { xs: 5, md: 0 },
              }}
            >
              {socials.map((social) => (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  key={social.name}
                  href={social.path}
                  aria-label={social.name}
                >
                  <IconButton aria-label={social.name}>
                    <Iconify icon={social.icon} />
                  </IconButton>
                </Link>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              justifyContent="space-between"
              direction={{ xs: 'column', md: 'row' }}
            >
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => {
                    const decodedPathname = decodeURIComponent(location.pathname);
                    const isCurrentLocation = decodedPathname === link.href;
                    if (!list.externalLinks) {
                      return (
                        <Link
                          key={link.name}
                          component={RouterLink}
                          to={link.href}
                          color="inherit"
                          variant="body2"
                          onClick={(event) => {
                            if (isCurrentLocation) {
                              event.preventDefault(); // Prevent navigation if it's the current location
                            }
                          }}
                        >
                          {link.name}
                        </Link>
                      );
                    }

                    return (
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        key={link.name}
                        href={link.href}
                        color="inherit"
                        variant="body2"
                      >
                        {link.name}
                      </Link>
                    );
                  })}
                </Stack>
              ))}
              <Box
                component="div"
                sx={{
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://qr.afip.gob.ar/?qr=mGPAeoxfBBdENFPa5dA1Vg,,"
                  color="inherit"
                  variant="body2"
                  sx={{ width: 250, height: 250 }}
                >
                  <Image
                    disabledEffect
                    alt="Data Fiscal - Código QR"
                    src={PaltaQR}
                    ratio="1/1"
                    sx={{ borderRadius: 1 }}
                  />
                  Data Fiscal
                </Link>
              </Box>
            </Stack>
          </Grid>
        </Grid>
        <Typography
          variant="caption"
          component="div"
          sx={{
            mt: 6,
            mb: 2,
            textAlign: { xs: 'center' },
          }}
        >
          A fin de garantizar el deber de publicidad y el derecho a la información de los usuarios,
          PALTA S.A.S. CUIT N° 30-71632481-4, con domicilio legal en Olegario V. Andrade 315,
          Ciudad, Mendoza informa que se encuentra inscripta en el Registro de Proveedores de
          Servicios de Pago del BCRA bajo el código N° 33.633,cumpliendo estrictamente con el
          régimen informativo y de vigilancia para monitorear su evolución. Cumpliendo con las
          disposiciones que establece la Comunicación A6885 y la Comunicación A6859 PALTA S.A.S. se
          limita a ofrecer servicios de pago y no se encuentra autorizada a operar como entidad
          financiera por el BCRA. Los fondos depositados en cuentas de pago no constituyen depósitos
          en una entidad financiera, ni cuentan con ninguna de las garantías que tales depósitos
          puedan gozar de acuerdo con la legislación y reglamentación aplicables en materia de
          depósitos en entidades financieras.
        </Typography>
        <hr />

        <Typography
          variant="caption"
          component="div"
          sx={{
            mt: 4,
            pb: 5,
            textAlign: { xs: 'center' },
          }}
        >
          © Copyright{' '}
          <Box component="span" color="text.green">
            Palta SAS.
          </Box>{' '}
          Todos los derechos reservados.
        </Typography>
      </Container>
    </Box>
  );
  return mainFooter;
}
