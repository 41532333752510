import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import CompactLayout from '../layouts/compact';
// config
//
import {
  //
  Page500,
  Page403,
  Page404,
  HomePage,
  MaintenancePage,
  LegalInformation,
  //
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [{ element: <HomePage />, index: true }],
    },
    {
      path: 'notes',
      element: <CompactLayout />,
      children: [
        { element: <Navigate to="/" replace />, index: true },
        { path: ':id', element: <LegalInformation /> },
      ],
    },
    {
      element: <CompactLayout isErrorPage />,
      children: [
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
