import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
// @mui
import { Stack, Container } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// config
import { HEADER } from '../../config-global';
//
import Header from './Header';
import Footer from '../main/Footer';

// ----------------------------------------------------------------------
CompactLayout.propTypes = {
  isErrorPage: PropTypes.bool
}

export default function CompactLayout({ isErrorPage = false }) {
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <>
      <Header isOffset={isOffset} />

      <Container component="main">
        <Stack
          sx={{
            m: 'auto',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Outlet />
        </Stack>
      </Container>
      {!isErrorPage && <Footer />}
    </>
  );
}
