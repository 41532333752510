import PropTypes from 'prop-types';
import React, { createContext, useState, useEffect, useMemo } from 'react';

const StoreContext = createContext();

const StoreProvider = ({ children }) => {
  const [stores, setStores] = useState([]);

  useEffect(() => {
    const storedData = localStorage.getItem('stores');
    if (storedData) {
      setStores(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('stores', JSON.stringify(stores));
  }, [stores]);

  const storeContextValue = useMemo(() => ({ stores, setStores }), [stores]);

  return <StoreContext.Provider value={storeContextValue}>{children}</StoreContext.Provider>;
};

StoreProvider.propTypes = {
  children: PropTypes.node,
};

export { StoreContext, StoreProvider };
