// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Inicio',
    icon: <Iconify icon="eva:home-fill" />,
    path: '#inicio',
  },
  {
    title: 'Nosotros',
    icon: <Iconify icon="icon-park-outline:avocado" />,
    path: '#nosotros',
  },
  {
    title: 'Comercios',
    icon: <Iconify icon="bi:shop" />,
    path: '#comercios',
  },
  {
    title: 'Crédito Fiscal',
    path: '/pages',
    icon: <Iconify icon="entypo:credit" />,
    children: [
      {
        subheader: 'Consulta',
        items: [
          {
            title: 'Venta para personas humanas',
            path: 'https://mendozafiduciaria.com/wp-content/uploads/2022/12/INSTRUCTIVO-CESIÓN-DE-CRÉDITO-FISCAL.pdf',
          },
          {
            title: 'Venta para personas jurídicas',
            path: 'https://mendozafiduciaria.com/wp-content/uploads/2022/12/INSTRUCTIVO-CESIÓN-DE-CRÉDITO-FISCAL-personas-juridicas.pdf',
          },
          {
            title: 'Cómo pagar Ingresos Brutos',
            path: 'https://storage.palta.app/statics/atm-billetera-virtual.pdf',
          },
        ],
      },
    ],
  },
  {
    title: 'Registro',
    icon: <Iconify icon="ic:round-app-registration" />,
    path: 'https://activa-app.palta.app/palta',
  },
];

export default navConfig;
